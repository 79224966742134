<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: center">
      <div style="padding: 10px; width: 300px">
        ОТ
        <input
          type="date"
          v-model="dateFrom"
          id="DateFrom"
          required
          class="form-control"
        />
        До
        <input
          type="date"
          v-model="dateBefore"
          id="DateBefore"
          required
          class="form-control"
        />
        <br />
        <button @click="getMarks()" class="btn btn-primary">Загрузить</button>
        <button
          class="btn btn-secondary"
          style="margin-left: 20px"
          @click="newPrintStat()"
        >
          Печать
        </button>
      </div>
    </div>
    <div style="display: flex; align-items: center; justify-content: center">
      <div style="padding: 10px; width: auto">
        <table id="tablePrint" v-if="showTable">
          <tr>
            <th>№</th>
            <th>Класс</th>
            <th>ФИ</th>
            <th>Категория</th>
            <th>Создана</th>
            <th>Обновлена</th>
            <th>Изменилась на</th>
            <th>Создана (день)</th>
            <th>Обновлена (день)</th>
            <th>Примечание</th>
          </tr>
          <tr v-for="(mark, index) in marks" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ mark.className }}</td>
            <td>{{ mark.FirstName }} {{ mark.LastName }}</td>
            <td>{{ mark.cat }}</td>
            <td>
              {{ formattedDate(mark.createdAt) }}
            </td>
            <td>
              {{ formattedDate(mark.updatedAt) }}
            </td>
            <td>{{ mark.causesID }}</td>
            <td>
              {{ new Date(mark.createdAt).toLocaleDateString() }}
            </td>
            <td>
              {{ new Date(mark.updatedAt).toLocaleDateString() }}
            </td>
            <td>
              <div
                v-if="
                  new Date(mark.createdAt).toLocaleDateString() !=
                  new Date(mark.updatedAt).toLocaleDateString()
                "
              >
                Изменение произошло в другой день!
              </div>
            </td>
          </tr>
        </table>
        <Preloader v-if="loading"></Preloader>
      </div>
    </div>
  </div>
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";
import Preloader from "./Preloader.vue";

export default {
  components: {
    Preloader: Preloader,
  },
  data() {
    return {
      loading: false,
      showTable: false,
      marks: [],
      dateBefore: "",
      dateFrom: "",
      classList: [],
      studentsList: [],
    };
  },
  methods: {
    newPrintStat() {
      //  let printHtml = document.getElementById("table").innerHTML; // Получаем содержимое узла для печати

      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0",
      );
      tab.document.write(
        document.getElementById("tablePrint").innerHTML +
          "<style>table, td, th {border: 1px solid#ddd;text-align: left;}table {border-collapse: collapse;width: 100%;}th, td {padding: 15px;}</style>",
      );
      // tab.document.close();
      tab.focus();
    },
    async getMarks() {
      this.loading = true;
      this.showTable = false;
      await TutorialDataService.findMarksByDateRange({
        classID: null,
        dateFrom: this.dateFrom,
        dateBefore: this.dateBefore,
        //studentID: studentID,
      })
        .then((response) => {
          let temp = Object.values(response.data);
          let changeTemp = [];
          console.log(temp);

          for (let i = 0; i < temp.length; i++) {
            if (temp[i].updatedAt != temp[i].createdAt) {
              changeTemp.push(temp[i]);
            }
          }

          this.marks = Object.values(changeTemp);
          this.marks.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));

          this.getClassInTable();
          this.getStudent();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getClassInTable() {
      console.log("CLASS", this.classList);
      for (let i = 0; i < this.classList.length; i++) {
        for (let j = 0; j < this.marks.length; j++) {
          if (this.marks[j].classID === this.classList[i].classID) {
            this.$set(this.marks[j], "className", this.classList[i].className);
          }
        }
      }
    },

    getFullNameStud() {
      for (let i = 0; i < this.studentsList.length; i++) {
        for (let j = 0; j < this.marks.length; j++) {
          if (this.marks[j].studentID === this.studentsList[i]._id) {
            this.$set(
              this.marks[j],
              "FirstName",
              this.studentsList[i].FirstName,
            );
            this.$set(this.marks[j], "LastName", this.studentsList[i].LastName);
          }
        }
      }
    },

    async getStudent() {
      await TutorialDataService.findStudentByClassID().then((response) => {
        let students = Object.values(response.data);
        students.map((student) => {
          if (student != undefined) {
            this.studentsList.push(student);
          }
        });
      });
      console.log(this.studentsList);
      await this.getFullNameStud();
      this.loading = false;
      this.showTable = true;
    },
    async getAllClass() {
      //получить список всех классов (classList)
      console.log("CLASS GET");
      await TutorialDataService.getAllCLass()
        .then((response) => {
          this.classList = response.data.map(this.getDisplayClass);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDisplayClass(data) {
      return {
        classID: data._id,
        className: data.className,
        shift: data.shift,
        change: false,
        count: 0,
        classLider: data.classLider,
      };
    },

    formattedDate: function (data) {
      let date = new Date(data);
      let temp;
      temp = date.toLocaleTimeString() + " " + date.toLocaleDateString();

      return temp;
    },
  },

  mounted() {
    this.dateBefore = new Date().toISOString().slice(0, 10);
    this.dateFrom = new Date().toISOString().slice(0, 10);
    this.getAllClass();
  },
};
</script>

<style scoped type="text/css">
TABLE {
  width: auto; /* Ширина таблицы */
  border-collapse: collapse; /* Убираем двойные линии между ячейками */
  font-size: 12px;
}
TD,
TH {
  padding: 3px; /* Поля вокруг содержимого таблицы */
  border: 1px solid black; /* Параметры рамки */
}
TH {
  background: #b0e0e6; /* Цвет фона */
}
</style>
